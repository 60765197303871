import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  PageMenuTemplate,
  Spinner,
  AccordionChildTemplate,
} from '../../components'

const EmergencyAssistanceView = ({
  data,
  loading,
  dataFiltered,
  onHandelFilterByGroup,
}) => {
  const { t, i18n } = useTranslation()
  const breakpoints = useBreakpoint()
  const arrayData = _.map(data.content, (i) => ({
    title: i.name,
    isOpenByDefault: false,
  }))

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <div className="p-3">
      {breakpoints.xs ? (
        <div>
          <div className="pageMenuTemplate__head">
            <h1 className="page-title">{t('telehealth.title')}</h1>
          </div>
          <AccordionChildTemplate
            data={arrayData}
            t={t}
            i18n={i18n}
            blue={true}
            html={_.get(dataFiltered, 'detail')}
          />
        </div>
      ) : (
        <PageMenuTemplate
          title={t('telehealth.title')}
          html={_.get(dataFiltered, 'detail')}
          param={'emergency-assistance'}
          menu={_.get(data, 'tab')}
          onHandelClick={onHandelFilterByGroup}
          i18n={i18n}
          t={t}
        />
      )}
    </div>
  )
}

export default EmergencyAssistanceView
